import styled from 'styled-components';

const ImageStyle = styled.div`
	--proportion: 50%;

	transition: background-color 0.2s ease-out;

	&.imageCont {
		position: relative;
		padding-top: var(--proportion);

		.image {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}

		img {
			object-fit: cover;
			object-position: center;
			transition: opacity 0.5s ease-out;
		}
	}

	&.contain {
		img {
			object-fit: contain;
		}
	}
`;

export default ImageStyle;
