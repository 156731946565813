import { forwardRef } from 'react';
import Link from 'next/link';
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';

const CustomLink = forwardRef(({ style, url, className, prefetch = false, scroll = true, replace = false, external = false, target, ariaLabel, children, content, disableCr, onMouseEnter, onMouseLeave, selectedLang }, ref) => {
	const href = typeof url === 'object' ? { ...url, pathname: external ? url.pathname : selectedLang == 'en' ? url.pathname : `/${selectedLang}${url.pathname}` } : external ? url : selectedLang == 'en' ? url : `/${selectedLang}${url}`;
	ariaLabel = ariaLabel || (url.replaceAll('/', '').includes('https') || url.replaceAll('/', '').includes('http') || url.replaceAll('/', '').includes('mailto') || url.replaceAll('/', '').includes('tel') ? url : url.replaceAll('/', ''));

	return (
		<Link
			href={href}
			style={style}
			scroll={scroll}
			replace={replace}
			prefetch={prefetch}
			legacyBehavior>
			<a
				ref={ref}
				className={`link-item ${!disableCr && 'crCircle'} ${className || ''}`}
				rel={external ? 'noopener noreferrer' : undefined}
				target={external ? '_blank' : target || '_self'}
				aria-label={ariaLabel}
				onMouseEnter={onMouseEnter || null}
				onMouseLeave={onMouseLeave || null}>
				{children || content}
			</a>
		</Link>
	);
});

export default withLanguageContext(CustomLink, ['selectedLang']);
