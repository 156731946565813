'use client';

import dynamic from 'next/dynamic';

import { ThemeProvider } from 'styled-components';

import Header from '@/components/global/Header';
const HighLevelComponent = dynamic(() => import('@components/HighLevelComponent'), {
	ssr: false,
});

//* Styles
import '@styles/icons/style.css';
import theme from '@styles/theme';
import HelperClass from '@styles/helperClass';
import Variables from '@styles/variables';
import Typography from '@styles/typography';

const RootTemplate = ({ children }) => {
	return (
		<ThemeProvider theme={theme}>
			<Variables />
			<Typography />
			<HelperClass />
			<Header />
			{children}
			<HighLevelComponent />
		</ThemeProvider>
	);
};

export default RootTemplate;
