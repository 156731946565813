'use client';

import { memo as Memo, useEffect, useState, useCallback, useRef } from 'react';
import { usePathname } from 'next/navigation';
import { TweenLite } from 'gsap';

//* HOC's
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';
import withUIContext from '@/context/consumerHOC/UIConsumer';

//* Components
import Logo from '@components/global/Logo';
import Burger from './HeaderComponent/Burger';
import MenuComponent from './HeaderComponent/MenuComponent';

//* Styles
import HeaderStyle from './style';

//!
let clickedCounter = 0;

const Header = Memo(({ selectedLang, languages, globalData }) => {
	//! Next Router
	const pathname = usePathname();

	//! States
	const [checked, setChecked] = useState(false);
	const [opened, setOpened] = useState(false);
	const [show, setShow] = useState(true);

	//! Refs
	const setTimeoutRef = useRef();

	useEffect(() => {
		if (checked && !opened) {
			setOpened(true);

			document.body.classList.add('modal-open');
		} else if (!checked && opened) {
			//! Close Burger-Menu
			setTimeoutRef.current = setTimeout(() => {
				document.body.classList.remove('modal-open');

				setOpened(false);
				setTimeout(() => {
					clickedCounter = 0;
				}, 700);
			}, 600);
		}
		return () => {
			//! Clear other timeout
			// if (setTimeoutRef.current) {
			// 	clearTimeout(setTimeoutRef.current);
			// }
		};
	}, [checked, opened]);

	useEffect(() => {
		const main = document.querySelector('main');
		const footer = document.querySelector('footer');

		if (main && footer) {
			if (opened) {
				main.style.opacity = 0;
				footer.style.opacity = 0;
			}
		}
	}, [opened]);

	const handleClick = (ev) => {
		clickedCounter += 1;

		if (clickedCounter > 2) {
			return;
		} else {
			//! Prev Content hidden
			const main = document.querySelector('main');
			const footer = document.querySelector('footer');

			if (main && footer) {
				if (ev.target.href) {
					if (ev.target.href.split('/').filter(Boolean).pop() == pathname.split('/').filter(Boolean).pop()) {
						main.style.opacity = 1;
						footer.style.opacity = 1;
					}
				} else if (opened && !ev.target.href) {
					main.style.opacity = 1;
					footer.style.opacity = 1;
				}
			}

			setChecked(!checked);
		}
	};

	const onLogoClick = useCallback((ev) => {
		const main = document.querySelector('main');
		const footer = document.querySelector('footer');

		if (main && footer) {
			main.style.opacity = 1;
			footer.style.opacity = 1;
		}

		setChecked(false);
	}, []);

	useEffect(() => {
		window.addEventListener('scroll', onScroll);

		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, []);

	let oldScroll = 0;
	const onScroll = useCallback(() => {
		if (window.scrollY > oldScroll && window.scrollY > 0 && !opened) {
			setShow(false);
		} else {
			setShow(true);
		}

		oldScroll = window.scrollY;
	}, [opened]);

	useEffect(() => {
		setChecked(false);
		setOpened(true);
		setOpened(false);

		clickedCounter = 0;
		document.body.classList.remove('modal-open');
	}, [selectedLang]);

	function clickedModal(ev) {
		if (ev.target?.href) {
			if (Object.keys(languages).includes(ev.target?.innerText?.toLowerCase())) {
				setChecked(false);
				setOpened(false);
				document.body.classList.remove('modal-open');
			}
		}
	}

	useEffect(() => {
		TweenLite.to('.Header', 0.3, { y: show ? '0%' : '-100%' });
	}, [show]);

	return (
		<HeaderStyle
			onClick={clickedModal}
			className={`header-container`}>
			<div className={`header-inner-cont ${opened ? 'mixed' : ''}`}>
				<div className='Header'>
					<Logo
						opened={opened}
						onClick={onLogoClick}
					/>

					<Burger
						checked={checked}
						onClick={handleClick}
					/>
				</div>
			</div>

			<MenuComponent
				opened={opened}
				onClick={handleClick}
				content={globalData?.contact_information}
			/>
		</HeaderStyle>
	);
});

export default withLanguageContext(withUIContext(Header, ['globalData']), ['selectedLang', 'languages']);
