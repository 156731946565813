import { useMemo } from 'react';

//* HOC's
import withUIContext from '@context/consumerHOC/UIConsumer';

//* Components
import Image from '../Image';
import CustomLink from '@components/global/CustomLink';

const Logo = ({ opened, lightHeader = true, onClick, darkMode, winWidth }) => {
	//! Screen check
	const isMobile = useMemo(() => {
		return winWidth < 768;
	}, [winWidth]);

	//! Logo color check
	const isLightLogo = useMemo(() => (lightHeader === 'reversed' && !darkMode) || (lightHeader && lightHeader !== 'reversed') || (darkMode && !lightHeader) || opened, [lightHeader, darkMode, opened]);

	//! Logo devise check
	const src = useMemo(() => `/images/headerLogoMenu/Logo${isMobile ? 'Mobile' : ''}${isLightLogo ? '' : 'Black'}.svg`, [isLightLogo, isMobile]);

	return (
		<CustomLink
			url='/'
			className='Logo'
			ariaLabel='Website Logo'
			content={
				<Image
					priority
					src={src}
					alt='logo'
					onClick={onClick}
					className={'contain'}
				/>
			}
		/>
	);
};

export default withUIContext(Logo, ['darkMode', 'winWidth']);
