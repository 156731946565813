import { memo as Memo } from 'react';

//* HOC's
import withUIContext from '@context/consumerHOC/UIConsumer';

//* Components
import CustomLink from '@/components/global/CustomLink';

const ContactInformation = Memo((props) => {
	return (
		<div className='contactInfoWrap'>
			{props.content.addresses.map((item, ind) => {
				return (
					<div
						key={ind}
						className='contactInfoItem'>
						<CustomLink
							external
							url={`tel:${item.phone.replace(/\s+/g, '')}`}
							className='p-m font-poppins font-medium font-italic phone crCircle'>
							{item.phone}
						</CustomLink>

						{props.winWidth >= props.screenSizes.screenXS && (
							<CustomLink
								external
								url={item.link}
								className='p-m font-poppins font-light font-italic location_address crCircle'>{`${item.address} ${item.location}`}</CustomLink>
						)}
					</div>
				);
			})}

			<CustomLink
				external
				url={`mailto:${props.content.mail}`}
				className='p-m font-medium font-italic font-poppins mail crCircle'>
				{props.content.mail}
			</CustomLink>
		</div>
	);
});

export default withUIContext(ContactInformation, ['winWidth', 'screenSizes']);
