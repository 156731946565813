import { useMemo } from 'react';
import { useSearchParams, usePathname } from 'next/navigation';
import Link from 'next/link';
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';
import Text from '../Text';

const LanguageSwitcher = ({ selectedLang, languages }) => {
	const pathname = usePathname();
	const searchParams = useSearchParams();

	const langs = useMemo(() => {
		return Object.keys(languages).map((lang) => {
			const route = pathname.replace(`/${selectedLang}`, '');
			const searchQuery = searchParams.toString();

			return (
				<li
					key={lang}
					className='lengItem p-m font-semi-bold font-poppins'>
					{selectedLang !== lang ? (
						<>
							<Text
								tag={'span'}
								className='hideText'>
								{lang}
							</Text>
							<Text
								tag={'span'}
								className='showText'>
								<Link
									className='crCircle'
									prefetch={false}
									locale={lang}
									href={{
										pathname: `/${lang}${route}`,
										query: searchQuery,
									}}>
									{lang}
								</Link>
							</Text>
						</>
					) : (
						<>
							<Text
								tag={'span'}
								className='hideText'>
								{lang}
							</Text>
							<Text
								tag={'span'}
								className='showText font-italic'>
								{lang}
							</Text>
						</>
					)}
				</li>
			);
		});
	}, [selectedLang, languages, pathname, searchParams]);

	return <ul className='lengWrap'>{langs}</ul>;
};

export default withLanguageContext(LanguageSwitcher, ['selectedLang', 'languages']);
