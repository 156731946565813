export default {
	colors: {
		white: '#ffffff',
		black: '#000000',
		red: '#DA3028',
	},

	mediaQuery: {
		DesktopSizeXL: '2560px',
		// DesktopSizeL: '1920px',
		DesktopSizeL: '1800px',
		DesktopSizeM: '1440px',
		DesktopSizeS: '1366px',
		DesktopSizeXS: '1280px',
		TabletSize: '1024px',
		TabletSizeS: '768px',
		MobileSize: '414px',

		DesktopSizeXLMin: '2559px',
		// DesktopSizeLMin: '1919px',
		DesktopSizeLMin: '1799px',
		DesktopSizeMMin: '1439px',
		DesktopSizeSMin: '1365px',
		DesktopSizeXSMin: '1279px',
		TabletSizeMin: '1023px',
		TabletSizeSMin: '767px',
		MobileSizeS: '413px',
	},
};
