import { memo as Memo, useRef, useState, useEffect } from 'react';
import { gsap } from 'gsap';

//* HOC's
import withUIContext from '@context/consumerHOC/UIConsumer';
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';

const Burger = Memo(({ checked, onClick, lightHeader, translate, winWidth }) => {
	//! Refs
	const contRef = useRef();
	const tl = useRef();

	//! States
	const [act, setAct] = useState(false);

	//! Animation
	useEffect(() => {
		tl.current = gsap
			.timeline({
				paused: true,
			})
			.to(contRef.current, 1, { strokeDashoffset: 600, ease: 'power4.out' });
	}, []);

	useEffect(() => {
		act ? tl.current.play() : tl.current.reverse();
	}, [act, tl]);

	return (
		<div
			onClick={onClick}
			onMouseEnter={winWidth >= 1280 ? () => setAct(true) : null}
			onMouseLeave={winWidth >= 1280 ? () => setAct(false) : null}
			className={`hamburger ${lightHeader === 'reversed' && !checked ? 'reversed' : checked || lightHeader ? 'white' : ''}`}>
			{checked ? translate('close') : translate('menu')}

			<div className='burgerLine'>
				<svg
					className='svg-max'
					viewBox='65.2 14.9 231 99'
					preserveAspectRatio='none'>
					<path
						ref={contRef}
						d='M225.7,92c0,0-169,49.6-157.8-9.1S283.1-2.9,293,35.1c9.9,37.9-66.8,76.1-66.8,76.1'
						fill='none'
					/>
				</svg>
			</div>
		</div>
	);
});

export default withUIContext(withLanguageContext(Burger, ['translate']), ['lightHeader', 'winWidth']);
