import { memo as Memo } from 'react';

//* Components
import CustomLink from '@/components/global/CustomLink';

const Social = Memo((props) => {
	return (
		<CustomLink
			external
			url={props.path}
			className={`crCircle`}
			ariaLabel='social-links'>
			<i className={props.icon} />
		</CustomLink>
	);
});

export default Social;
